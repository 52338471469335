import React from 'react';
import Game from './Game';

function App() {
  return (
    <div className="App">
      { /*<div className="ad-bar">Try this <a href="https://www.tikitakatoe.org/" target="_blank">Play Tiki Taka Toe Football Grid!</a> 🎮</div> */ }
      <Game />
    </div>
  );
}

export default App;
