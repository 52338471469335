import React, { useState, useEffect } from 'react';
import data from './data.json';
import { Helmet } from 'react-helmet'; // Add this import
import seedrandom from 'seedrandom';

import './Game.css'; 

function Game() {
    const [gameData, setGameData] = useState([]);
    const [unplayedGames, setUnplayedGames] = useState([]);
  const [currentGame, setCurrentGame] = useState(null);
  const [currentGuess, setCurrentGuess] = useState([]);
  const [foundGroups, setFoundGroups] = useState({});
  const [attempts, setAttempts] = useState(4);
  const [message, setMessage] = useState('');
  const [animation, setAnimation] = useState(null);
  const [animationWords, setAnimationWords] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const [gameId, setGameId] = useState(null); // Add this line
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [selectionStreak, setSelectionStreak] = useState([]);

  const levelColors = ['#fbd400', '#b5e352', '#729eeb', '#bc70c4'];
  const levelEmojis = ["🔵", "🟢", "🟡", "🔴", "🟣"]; // Choose your own emojis


  useEffect(() => {
    setTimeElapsed(0);  // Reset the timer when starting a new game
  
    // Other codes
  
    const timer = setInterval(() => {
      setTimeElapsed(timeElapsed => timeElapsed + 1);
    }, 1000);
  
    return () => clearInterval(timer);
  }, [gameId]);  // Only depend on gameId

  function mixGroups(gameId) {
    const rng = seedrandom(gameId); // Create seeded random number generator
    let newGame = { groups: {}, startingGroups: [[], [], [], []] };
    let usedWords = new Set();
  
    for(let i=0; i<4; i++) {
      let randomId, randomGame, groupKey;
  
      // Keep trying until a suitable group is found
      do {
        do {
          randomId = Math.floor(rng() * data.length);
        } while(randomId === gameId); // Ensure that we don't select the same game
  
        randomGame = data[randomId];
  
        do {
          groupKey = Object.keys(randomGame.groups)[Math.floor(rng() * Object.keys(randomGame.groups).length)];
        } while(randomGame.groups[groupKey].level !== i);
      } while(randomGame.groups[groupKey].members.some(member => usedWords.has(member))); // Check if the members are already used
  
      newGame.groups = {...newGame.groups, [groupKey]: randomGame.groups[groupKey]};
          
      // Shuffle members into startingGroups
      randomGame.groups[groupKey].members.forEach((member, index) => {
        usedWords.add(member); // Add the member to the usedWords set
  
        let insertionRow;
        do {
          insertionRow = Math.floor(rng() * 4);
        } while (newGame.startingGroups[insertionRow].length === 4);
        newGame.startingGroups[insertionRow].push(member);
      });
    }
    
    return newGame;
  }
  
  const shuffleArray = (array) => {
    let currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  };
  
  

  useEffect(() => {
    setGameData(data);
    setTimeElapsed(0);
    setUnplayedGames([...Array(data.length).keys()]);
    let gameIndex;

    const urlParams = new URLSearchParams(window.location.search);
    const gameId = urlParams.get('gameId');
    setGameId(gameId);
    if(gameId){
        gameIndex = gameId;
        const mixedGame = mixGroups(gameIndex);
        
        // Shuffle each row in startingGroups
        let gameSh = mixedGame.startingGroups = mixedGame.startingGroups.map(group => shuffleArray(group));
        setCurrentGame(gameSh);
    } else {
        gameIndex = generateIndexConnections() % data.length;
        let game = data[gameIndex];
        
        // Shuffle each row in startingGroups
        game.startingGroups = game.startingGroups.map(group => shuffleArray(group));
        setCurrentGame(game);
    }

    setUnplayedGames(prevUnplayedGames => prevUnplayedGames.filter(index => index !== gameIndex));
    // Reset the timer and selection streak when a new game is started
    setSelectionStreak([]);
  }, [gameId]);



  function generateIndexConnections() {
    const D = 864e5;
    const e = new Date("10/3/2023");
    const t = (new Date()).setHours(0, 0, 0, 0) - e.setHours(0, 0, 0, 0);
    let n = Math.round(t / D);
    return n < 0 ? Math.abs(n) : n;
  }

  const handleWordClick = word => {
    // If the word is already selected, remove it from the currentGuess
    if (currentGuess.includes(word)) {
      setCurrentGuess(currentGuess.filter(guess => guess !== word));
    } 
    // Else if there are less than 4 items in the currentGuess, add the word
    else if (currentGuess.length < 4) {
      setCurrentGuess([...currentGuess, word]);
    } 
    // If there are 4 items already and the word isn't one of them, ignore the click
    else {
      return;
    }
  };

  
  

  const submitGuess = () => {
    if (currentGuess.length !== 4) {
        setMessage('Você precisa selecionar 4 elementos antes de enviar.');
        return;
      }
      console.log(currentGame)
      const guessedGroup = Object.entries(currentGame.groups).find(
        ([groupName, group]) =>
          JSON.stringify(group.members.sort()) === JSON.stringify(currentGuess.sort())
      );

    if (guessedGroup) {
      setFoundGroups({
        ...foundGroups,
        [guessedGroup[0]]: guessedGroup[1].members,
      });

      const newStartingGroups = currentGame.startingGroups
        .flat()
        .filter(word => !currentGuess.includes(word));

      setCurrentGame({
        ...currentGame,
        startingGroups: chunkArray(newStartingGroups, 4),
      });
      
      setMessage('Parabéns! Você encontrou um grupo!');
        setAnimation('success');
        setAnimationWords(currentGuess);
        setTimeout(() => {
            setCurrentGuess([]);
            setAnimation(null);
            setAnimationWords([]);
        }, 1000);
    } else {
        setAnimation('error');
        setAnimationWords(currentGuess);
        setTimeout(() => {
            setAnimation(null);
            setAnimationWords([]);
        }, 1000);
      setMessage('Resposta incorreta, por favor tente novamente.');
        setAttempts(prevAttempts => {
            if(prevAttempts - 1 === 0) {
                setGameOver(true);
                setMessage('Jogo terminado! Todos os tentativas se esgotaram!');
            }
            return prevAttempts - 1;
        });
    }

    const wordLevels = currentGuess.map((word) => {
      // Find the group to which the word belongs
      const groupName = Object.keys(currentGame.groups).find(
        key => currentGame.groups[key].members.includes(word)
      );
    
      // Get the level of this group
      const level = currentGame.groups[groupName].level;
    
      // Return the word and its corresponding level
      return { word, level };
    });
  
    setSelectionStreak([...selectionStreak, wordLevels]);
  };

  const startNewGame = () => {
    if (unplayedGames.length === 0) {
      setMessage('Não há mais jogos!');
      return;
    }

    const randomIndex = Math.floor(Math.random() * unplayedGames.length);
    const nextGameIndex = unplayedGames[randomIndex];
    setUnplayedGames(prevUnplayedGames => prevUnplayedGames.filter(index => index !== nextGameIndex));

    // Redirect to new page with gameId as URL parameter
    window.location.href = window.location.origin + '?gameId=' + nextGameIndex;
  };

  const restartGame = () => {
    window.location.reload();
  };

  const resetGuess = () => {
    setCurrentGuess([]);
  };

  const copyResultsToClipboard = () => {
    // Create a string from the selection streak
    const resultsString = selectionStreak.map((guess, index) => 
      guess.map((wordObj) => `${levelEmojis[wordObj.level]}`).join('')
    ).join('');
  
    // Copy the string to the clipboard
    navigator.clipboard.writeText('ConexoJogo.org - ' + resultsString);
    // Show an alert message
    alert('Copiado para a área de transferência, agora você pode compartilhar o resultado nas redes sociais.');
  };
  
  

  return (
    <div className="game-container">
      {gameId && (
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      )}
      <div className="game-header">
        <h1>Conexo Jogo</h1>
        <p className="subtitle">Conexão de Termos em Português</p>
        <p>Conexo Jogo - uma versão em português, similar ao Wordle e sem fim, do popular jogo de Conexões do New York Times. Melhore seu vocabulário e divirta-se de forma ilimitada encontrando grupos de palavras.</p>
       </div>
       
       
        {Object.entries(foundGroups).map(([groupName, words], index) => (
        <div
          className="game-group"
          key={groupName}
          style={{ backgroundColor: levelColors[index % levelColors.length] }}
        >
          <h3 className="group-name">{groupName}</h3>
          <div className="group-members">{words.join(', ')}</div>
        </div>
      ))}

<div class="top-g"><p class="p-text">Cria quatro grupos de quatro.</p><div class="game-time">Tempo: {`${Math.floor(timeElapsed / 60).toString().padStart(2, '0')}:${(timeElapsed % 60).toString().padStart(2, '0')}`}</div></div>
      

      {currentGame && currentGame.startingGroups.map((group, groupIndex) => (
        <div className="game-board" key={groupIndex}>
          {group.map((word, wordIndex) => (
            <button
            className={`game-item 
              ${currentGuess.includes(word) ? 'selected' : ''} 
              ${animationWords.includes(word) ? `${animation}-animation` : ''} 
              ${word.length === 8 ? 'size-8' : ''} 
              ${word.length > 8 ? 'size-more' : ''}`}
            key={wordIndex}
            onClick={() => handleWordClick(word)}
          >
            {word || ' '}
          </button>
          
          ))}
        </div>
      ))}
      {message && <div className="message">{message}</div>}
      {gameOver ? (
        <>
          <button className="game-btn" onClick={restartGame}>Reiniciar jogo</button>
          <button className="game-btn" onClick={startNewGame}>Novo jogo</button>
        </>
      ) : (
        <>
          {currentGame && Object.keys(foundGroups).length === Object.keys(currentGame.groups).length ? (
            <>
                <div className="congratulations">
                    <h2>Parabéns!</h2>
                    <p>Você encontrou todos os grupos!</p>
                    <div className="share">
  {selectionStreak.map((guess, index) => (
    <div key={index} className="share-row">
      {guess.map((wordObj, i) => (
        <span key={i} style={{ backgroundColor: levelColors[wordObj.level] }}>
          
        </span>
      ))}
    </div>
  ))}
</div>
<button className="game-btn" style={{ backgroundColor:'#000000', color:'#fff', marginTop:'10px' }} onClick={copyResultsToClipboard}>Share Results</button>
                </div>
                <button className="game-btn" onClick={startNewGame}>Novo Jogo</button>
            </>
        
          ) : (
            <div className="btn-wrapper">
              <button className="game-btn submit-btn" onClick={submitGuess}>Enviar</button>
              <button className="game-btn" onClick={resetGuess}>Desmarcar</button>
              { /*<button className="game-btn" onClick={startNewGame}>Nuevo Juego</button> */ }
            </div>
          )}
          <div className="game-attempts">Erros restantes: {attempts}</div>
        </>
      )}
      <div className="content">
        <h3 className="for-content">Como jogar Conexo Jogo Português?</h3>
        
        <ul>
            <li><strong>Passo 1:</strong> O jogo apresenta vários elementos, dos quais você precisa encontrar grupos de quatro que têm algo em comum.</li>
            <li><strong>Passo 2:</strong> Assim que identificar um grupo, selecione os quatro elementos e pressione 'Enviar' para verificar se sua suposição está correta.</li>
            <li><strong>Passo 3:</strong> O objetivo é encontrar todos os grupos sem cometer 4 erros!</li>
        </ul>
        <img className="ill" src="https://www.conexojogo.org/como-jogar-conexo.png" alt="como jogar o jogo de conexão" />
        <p><strong>Ajuda adicional:</strong> Lembre-se que as categorias sempre serão mais específicas que "palavras de 5 letras", "nomes" ou "verbos". <strong>As categorias podem ser complicadas!</strong> Cada grupo identificado é atribuído uma cor, que é revelada à medida que você avança. As cores vão do mais simples (categorias facilmente identificáveis) ao mais complexo (categorias mais complexas ou abstratas).</p>
      </div>

      <div className="footer">
        
        <div className="disclaimer">Aviso legal: Conexo Português é um produto independente e não está afiliado, nem foi autorizado, patrocinado ou de outra forma aprovado pela The New York Times Company. Encorajamos você a jogar o jogo diário do NYT Conexões no site do The New York Times.</div>
        <div className="contact"><a href="privacy.html">Política de Privacidade</a> | Contato: <a href="mailto:connectionsunlimitedcom@gmail.com">connectionsunlimitedcom(at)gmail.com</a></div>
      </div>
    </div>
  );
}

function chunkArray(array, size) {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
}

export default Game;